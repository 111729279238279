import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Controls from "../components/Controls";
import { getSliderTitles } from "../lib/sliderTitles";
import { currencyToSymbol } from "../lib/currencySymbol";
import {
  setChurn,
  setFixedCosts,
  setGrowth,
  setPrice,
  setStartingCosts,
  setStartingUsers,
  setVariableCosts,
  setMax,
  setChangeMaxModeActive,
} from "../reducers";

const ControlsContainer = () => {
  const dispatch = useDispatch();
  const {
    churn,
    fixedCosts,
    growth,
    model,
    currency,
    price,
    startingCosts,
    startingUsers,
    timeUnit,
    variableCosts,
    max,
    isChangeMaxModeActive,
  } = useSelector((state) => state.app);

  const sliderTitles = getSliderTitles(model, timeUnit);
  const currencySymbol = currencyToSymbol(currency);

  const updateChurn = (value) => dispatch(setChurn(value));
  const updateFixedCosts = (value) => dispatch(setFixedCosts(value));
  const updateGrowth = (value) => dispatch(setGrowth(value));
  const updatePrice = (value) => dispatch(setPrice(value));
  const updateStartingCosts = (value) => dispatch(setStartingCosts(value));
  const updateStartingUsers = (value) => dispatch(setStartingUsers(value));
  const updateVariableCosts = (value) => dispatch(setVariableCosts(value));
  const changeMax = (value) => dispatch(setMax(value));
  const changeMaxMode = (value) => dispatch(setChangeMaxModeActive(value));

  return (
    <Controls
      sliderTitles={sliderTitles}
      churn={churn}
      fixedCosts={fixedCosts}
      growth={growth}
      price={price}
      startingCosts={startingCosts}
      startingUsers={startingUsers}
      updateChurn={updateChurn}
      updateFixedCosts={updateFixedCosts}
      updateGrowth={updateGrowth}
      updatePrice={updatePrice}
      updateStartingCosts={updateStartingCosts}
      updateStartingUsers={updateStartingUsers}
      updateVariableCosts={updateVariableCosts}
      variableCosts={variableCosts}
      max={max}
      isChangeMaxModeActive={isChangeMaxModeActive}
      changeMaxMode={changeMaxMode}
      changeMax={changeMax}
      currencySymbol={currencySymbol}
    />
  );
};

export default ControlsContainer;
