/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
const profitZeroStringCalc = (profit, profitZero, timeUnit) => {
  if (isNaN(profitZero)
  || (!isFinite(profitZero) && profit[1] < 0)
  || (profitZero <= 0 && profit[1] < 0)) {
    return 'Never profitable';
  } else if (profitZero <= 0 && profit[1] > 0) {
    return 'Immediately profitable';
  }
  return `Profitable in ${timeUnit} ${Math.ceil(profitZero)}`;
};

export default profitZeroStringCalc;
