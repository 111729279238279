import { Chart as ChartJS } from "chart.js/auto";
import { arrayCreator } from "./calculators/array";
import { capitalizeFirstLetter, compactNumber } from "./formats";
import { orange, blue, greyChartLine } from "./colors";

let chart = null;

export const renderChart = (dataLeft, dataRight, timeUnit, currencySymbol) => {
  const ctx = document.getElementById("myChart").getContext("2d");

  const labels = arrayCreator(dataLeft.length);

  chart = new ChartJS(ctx, {
    // The type of chart we want to create
    type: "line",

    // The data for our dataset
    data: {
      labels,
      datasets: [
        {
          backgroundColor: "transparent",
          borderColor: blue,
          data: dataLeft,
          yAxisID: "profit",
          borderWidth: 2,
          label: "Profit",
          pointBackgroundColor: blue,
          tension: 0.4,
        },
        {
          backgroundColor: "transparent",
          borderColor: orange,
          data: dataRight,
          yAxisID: "users",
          borderWidth: 2,
          label: "Users",
          pointBackgroundColor: orange,
          tension: 0.4,
        },
      ],
    },

    // Configuration options go here
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label(context) {
              const label = context.dataset.label;
              const value = context.parsed.y;
              return `${label}: ${
                label === "Profit"
                  ? currencySymbol + compactNumber(value)
                  : compactNumber(value)
              }`;
            },
            title(context) {
              return `${capitalizeFirstLetter(timeUnit)} ${context[0].label}`;
            },
          },
        },
      },
      animation: false,
      scales: {
        x: {
          display: true,
          ticks: {
            maxTicksLimit: 5,
            fontColor: "#8A8A8A",
            fontSize: 13,
          },
          grid: {
            color: greyChartLine,
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: false,
          },

          scaleLabel: {
            display: true,
            labelString: `${capitalizeFirstLetter(timeUnit)}s`,
          },
        },

        profit: {
          type: "linear",
          position: "left",
          ticks: {
            callback: function (value) {
              return `${currencySymbol + compactNumber(value)}  `;
            },
            maxTicksLimit: 4,
            color: "#8A8A8A",
            font: {
              size: 13,
            },
          },
          grid: {
            color: (context) => {
              if (context.tick.value === 0) {
                return "rgba(255, 255, 255, 0.8)"; // Color for the zero line
              }
              return greyChartLine; // Color for other grid lines
            },
            lineWidth: (context) => {
              if (context.tick.value === 0) {
                return 2; // Thickness for the zero line
              }
              return 1; // Thickness for other grid lines
            },
          },
          border: {
            display: false,
          },
        },
        users: {
          type: "linear",
          position: "right",
          ticks: {
            callback: function (value) {
              return `${compactNumber(value)}  `;
            },
            maxTicksLimit: 4,
            color: "#8A8A8A",
            font: {
              size: 13,
            },
          },
          grid: {
            display: false,
          },
        },
      },
    },
  });
};

export const updateChartTimeScale = (newTimeUnit) => {
  if (!chart) return;

  // Update x-axis configuration
  chart.options.scales.x.time.unit = newTimeUnit;
  chart.options.scales.x.title.text = `${capitalizeFirstLetter(newTimeUnit)}s`;

  // Update tooltip title
  chart.options.plugins.tooltip.callbacks.title = (context) => {
    return `${capitalizeFirstLetter(newTimeUnit)} ${context[0].label}`;
  };

  chart.update();
};

export const destroyChart = () => {
  if (chart) {
    chart.destroy();
    chart = null;
  }
};
