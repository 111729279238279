/* eslint-disable max-len */
export const E_COMMERCE = "E_COMMERCE";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const modelSwitchOptions = [SUBSCRIPTION, E_COMMERCE];

export const YEAR = "year";
export const MONTH = "month";
export const WEEK = "week";
export const timeSwitchOptions = [YEAR, MONTH, WEEK];

export const EUR = "eur";
export const USD = "usd";
export const currencySwitchOptions = [EUR, USD];

export const NON_CUMULATIVE = "NON_CUMULATIVE";
export const CUMULATIVE = "CUMULATIVE";

export const UNICORN = "img/unicorn.svg";
export const SNAKE = "img/snake.svg";
export const SKULL = "img/skull.svg";

export const SUBSCRIPTION_TEMPLATE =
  "16eUl6XLm-Ztzyu4tzUrLNzf7gJjc2p-L5T4BeqbVHbA";
export const ECOMMERCE_TEMPLATE =
  "16eUl6XLm-Ztzyu4tzUrLNzf7gJjc2p-L5T4BeqbVHbA";
export const ZAPIER_URL =
  "https://hooks.zapier.com/hooks/catch/4208615/p3044s/";

export const TMI_URL =
  "https://themainingredient.co/?utm_source=startup-calculator&utm_campaign=made-by-button";
export const TYPEFORM_URL =
  "https://themainingredient.typeform.com/to/hIP5u0TY";

export const SENTRY_DNS_URL =
  "https://ac11653c77454bf7b84766c9b0c1e7e8@sentry.io/1390076";
export const LOGROCKET_ID = "qtbpwn/startup-calculator";

export const LINKEDIN_SHARE_URL =
  "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fstartupcalculator.co%3Futm_source%3Dlinkedin%26utm_medium%3Dsocial%26utm_campaign%3Dshare-button";

export const POSTHOG_API_KEY =
  "phc_bs54YY0QgBVNySD1oQbJ7oULFduSs3VPQ6unLnkN8QH";
