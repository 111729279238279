const profitCalc = (
  fixedCosts,
  price,
  startingCosts,
  time,
  users,
  variableCosts,
) => {
  const profit = [];

  profit.push(0 - startingCosts);

  for (let i = 1; i <= time; i += 1) {
    profit.push(users[i] * (price - variableCosts) - fixedCosts);
  }

  return profit;
};

export default profitCalc;
