export const getUsers = (
  churn,
  growth,
  startingUsers,
  time,
) => {
  const users = [];

  for (let i = 0; i <= time; i += 1) {
    users.push(startingUsers * (Math.exp(Math.log((growth - churn) / 100 + 1) * i)));
  }

  return users;
};

export const getNonCumulativeUsers = (
  time,
  users,
) => {
  const nonCumulativeUsers = [];

  nonCumulativeUsers.push(users[0]);

  for (let i = 1; i <= time; i += 1) {
    nonCumulativeUsers.push(users[i] - users[i - 1]);
  }

  return nonCumulativeUsers;
};
