/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
  sliderRail,
  sliderTrack,
  greyButton,
  greyButtonBackground,
} from "../lib/colors";
import { usePostHog } from "posthog-js/react";

const MetricSlider = ({
  metric,
  onChange,
  max,
  min,
  title,
  format,
  tooltip,
  isChangeMaxModeActive,
  updateMax,
  variable,
  currencySymbol,
}) => {
  const handleBlur = (e) => {
    const newMax = parseInt(e.target.value, 10);
    updateMax(variable, newMax, title);
  };

  const posthog = usePostHog();

  const step = max / 100;

  const posthogEvent = () => {
    posthog?.capture("change-slider", { sliderTitle: title, value: metric });
  };

  const renderRightLabel = () => {
    if (isChangeMaxModeActive) {
      return (
        <div className="column is-two-fifths pulled-right">
          <p className="slider-label has-text-weight-bold has-text-right is-size-13">
            <input
              type="text"
              defaultValue={Math.round(max)}
              onBlur={handleBlur}
              className="is-inline slider-max-input"
            />
          </p>
        </div>
      );
    }
    return null;
  };

  const handle = {
    borderColor: greyButton,
    borderWidth: 9,
    height: 24,
    width: 24,
    marginLeft: -12,
    marginTop: -9,
    backgroundColor: greyButtonBackground,
    opacity: 1,
  };

  return (
    <div className="mt-5">
      <div className="columns is-vcentered mb-0 is-mobile">
        <div className="column is-one-fifth pulled-left">
          <p className="slider-label has-text-weight-bold has-text-left is-size-13">
            {currencySymbol ? currencySymbol + format(metric) : format(metric)}
          </p>
        </div>

        <div
          className={
            isChangeMaxModeActive
              ? "px-0 column is-two-fifths"
              : "px-0 column is-three-fifths"
          }
        >
          <div className="is-size-13 has-text-centered no-select">
            {title}&nbsp;
            <FontAwesomeIcon
              icon={faInfoCircle}
              data-tooltip-id={`tooltip-${title}`}
            />
            <Tooltip id={`tooltip-${title}`} content={tooltip} />
          </div>
        </div>

        {renderRightLabel()}
      </div>

      <div className="slider-container">
        <div className="slider no-select">
          <Slider
            className={`plausible-event-name=Change+Slider+${title}`}
            max={max}
            min={min || 0}
            step={step}
            defaultValue={metric}
            onChange={onChange}
            onChangeComplete={posthogEvent}
            value={metric}
            styles={{
              track: { background: sliderTrack, height: 5 },
              rail: { backgroundColor: sliderRail, height: 5 },
              handle: handle,
            }}
          />
        </div>
      </div>
    </div>
  );
};

MetricSlider.propTypes = {
  metric: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number,
  title: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
  isChangeMaxModeActive: PropTypes.bool.isRequired,
  updateMax: PropTypes.func.isRequired,
  variable: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string,
};

// MetricSlider.defaultProps = {
//   min: 0,
//   currencySymbol: "",
// };

export default MetricSlider;
