import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUnit,
  changeModel,
  changeCurrency,
  initialState,
} from "../reducers";
import { Switches } from "../components/Switches";
import {
  SUBSCRIPTION,
  E_COMMERCE, // Changed from ECOMMERCE to E_COMMERCE
  YEAR,
  MONTH,
  WEEK,
  EUR,
  USD,
} from "../lib/const";
import { usePostHog } from "posthog-js/react";

const SwitchesContainer = () => {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.app);
  const posthog = usePostHog();

  const { timeUnit, model, currency } = appState;

  const handleModelChange = (selectedOption) => {
    dispatch(changeModel(selectedOption.value));
    posthog?.capture("change-model", { model: selectedOption.label });
  };

  const handleTimeUnitChange = (selectedOption) => {
    dispatch(
      changeUnit({ timeUnit: selectedOption.value, oldTimeUnit: timeUnit })
    );
    posthog?.capture("change-time-unit", { model: selectedOption.label });
  };

  const handleCurrencyChange = (selectedOption) => {
    dispatch(changeCurrency(selectedOption.value));
    posthog?.capture("change-currency", { model: selectedOption.label });
  };

  const modelOptions = [
    { value: SUBSCRIPTION, label: "Subscription" },
    { value: E_COMMERCE, label: "E-commerce" }, // Changed from ECOMMERCE to E_COMMERCE
  ];

  const timeOptions = [
    { value: YEAR, label: "Year" },
    { value: MONTH, label: "Month" },
    { value: WEEK, label: "Week" },
  ];

  const currencyOptions = [
    { value: EUR, label: "EUR" },
    { value: USD, label: "USD" },
  ];

  return (
    <Switches
      timeUnit={timeUnit || YEAR} // Provide a default value
      model={model || SUBSCRIPTION} // Provide a default value
      currency={currency || EUR} // Provide a default value
      handleTimeUnitChange={handleTimeUnitChange}
      handleModelChange={handleModelChange}
      handleCurrencyChange={handleCurrencyChange}
      modelOptions={modelOptions}
      timeOptions={timeOptions}
      currencyOptions={currencyOptions}
    />
  );
};

export default SwitchesContainer;
