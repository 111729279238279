import { EUR, USD } from './const';

export const currencyToSymbol = (currency) => {
  switch (currency) {
    case EUR:
      return '€';
    case USD:
      return '$';
    default:
      return '€';
  }
};
