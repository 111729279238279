import React from "react";
import { TMI_URL, TYPEFORM_URL } from "../lib/const";
import { usePostHog } from "posthog-js/react";

export const Footer = () => {
  const posthog = usePostHog();
  const handleCtaClick = () => {
    window.open(TYPEFORM_URL, "_blank");

    posthog?.capture("ctaClick");
  };

  const handleTmiClick = () => {
    window.open(TMI_URL, "_blank");

    posthog?.capture("tmiClick");
  };

  return (
    <footer className="footer-bg has-background-grey-darker">
      {/* <div
        role="button"
        tabIndex="0"
        className="is-pulled-left ignition-cta-container hover-pointer plausible-event-name=Form+Click"
        onClick={handleCtaClick}
      >
        <p className="is-size-12 mb-3">
          We are building Startup Calculator 2.0 and need your help!
        </p>

        <div className="ignition-logo-container has-background-grey-darker is-inline-block">
          <img src="img/startup_calculator_2_0.svg" alt="ignition-logo" />
        </div>

        <p className="ignition-link link is-block has-text-centered">
          help shape Startup Calculator 2.0
        </p>
      </div> */}

      <div className="container footer-container">
        <div className="is-pulled-right footer-logo">
          <div
            role="button"
            tabIndex="0"
            onClick={handleTmiClick}
            className="hover-pointer plausible-event-name=TMI+Click"
          >
            <p className="v-middle has-text-light is-inline-block">
              Made by <strong>The Main Ingredient</strong>
            </p>

            <img
              className="is-inline-block v-middle logo"
              src="img/tmi-logo.svg"
              width="25"
              height="25"
              alt="tmi logo"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
