const profitZeroCalc = (
  churn,
  fixedCosts,
  growth,
  price,
  startingUsers,
  variableCosts,
) => {
  const profitZero = (Math.log(fixedCosts / ((price - variableCosts) * startingUsers)) / ((growth - churn) / 100));

  return profitZero;
};

export default profitZeroCalc;
