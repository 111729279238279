import React from "react";
import PropTypes, { oneOfType } from "prop-types";

const Advice = (props) => {
  const { adviceArray } = props;

  const adviceList = adviceArray.map((advice) => (
    <div key={advice.key} className="is-size-12 fade-in mb-2 mr-2">
      <img className="notification-dot" src="img/orange-dot.svg" alt="dot" />
      <div className="advice-text">
        <span className="has-text-weight-bold is-block">{advice.title}</span>
        {advice.content}
      </div>
    </div>
  ));

  return (
    <div className="">
      <h3 className="is-size-5 is-family-secondary">
        Our Expert Advice{" "}
        {adviceArray.length > 0 ? `(${adviceArray.length})` : ""}
      </h3>
      <div className="has-text-weight-bold">
        Move the sliders for customized advice
      </div>
      <div className="advice-container mt-3">
        <div className="advice-list">{adviceList}</div>
      </div>
    </div>
  );
};

Advice.propTypes = {
  adviceArray: PropTypes.arrayOf(
    PropTypes.objectOf(oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
};

export default Advice;
