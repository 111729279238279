/* eslint-disable max-len */
export const lowChurn = {
  title: 'Churn looks low',
  content: 'Typically the churn rate lies within the 3-7% range for a subscription service.',
  key: 1,
};

export const noFunding = {
  title: 'Bootstrap 🚀🚀🚀',
  content: 'Congratulations, you don\'t need funding. This means more flexibility and higher autonomy for the founding team.',
  key: 2,
};

export const sliderMax = {
  title: 'Need bigger sliders?',
  content: 'You can customize the range of the sliders by clicking on the \'Change slider range\' button',
  key: 3,
};

export const tenThousandUsers = {
  title: 'More than 10K users in 5 years 🥳',
  content: 'With this kinda growth you\'re about to become the next Facebook!',
  key: 4,
};

export const zeroStartingUsers = {
  title: 'You need some launching users',
  content: 'The calculator will use your launching users as a basis for your net growth, without launching users nothing happens.',
  key: 5,
};

export const notProfitableWithinSevenYears = {
  title: 'Aim to be profitable within 7 years',
  content: 'Entrepeneurship is a marathon, not a sprint. Although small business may be profitable from day 1 you are a Startup 🚀 and Startup = Growth! Therefore aim to be profitable before year 7 with positive gross margins before that',
  key: 6,
};

export const costsHigherThanPrice = {
  title: 'You spend more than you earn',
  content: 'This means you\'ll lose more money with every customer you acquire. Try to reduce variable costs or increase your pricing.',
  key: 7,
};

export const sliderMin = {
  title: 'Need more precise sliders?',
  content: 'You can customize the range of the sliders by clicking on the \'Change slider range\' button',
  key: 8,
};

export const extremeGrowth = {
  title: 'Hold your horses cowboy! 🤠',
  content: 'Realistic growth percentages depend per company stage. Your unicorn peers were experiencing an average growth of 130% in their 0-25 million revenue range. What makes you different?',
  key: 9,
};
