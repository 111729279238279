import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Results from "../components/Results";
import { NON_CUMULATIVE } from "../lib/const";
import { getData } from "../lib/calculators/getData";
import { currencyToSymbol } from "../lib/currencySymbol";
import { setChartDataType } from "../reducers"; // Import the action creator

const ResultsContainer = () => {
  const dispatch = useDispatch();
  const {
    chartDataType,
    churn,
    fixedCosts,
    growth,
    model,
    currency,
    price,
    startingCosts,
    startingUsers,
    timeRange,
    timeUnit,
    variableCosts,
  } = useSelector((state) => state.app);

  const {
    users,
    nonCumulativeUsers,
    profit,
    cumulativeProfit,
    fundingNeeded,
    profitZeroString,
    customerLifeTime,
    customerLifeTimeValue,
  } = getData(
    churn,
    fixedCosts,
    growth,
    price,
    startingCosts,
    startingUsers,
    timeRange,
    timeUnit,
    variableCosts
  );

  const cumulativeChartData = [cumulativeProfit, users];
  const nonCumulativeChartData = [profit, nonCumulativeUsers];
  const chartData =
    chartDataType === NON_CUMULATIVE
      ? nonCumulativeChartData
      : cumulativeChartData;

  const wordForUsers = model === "subscription" ? "users" : "customers";
  const currencySymbol = currencyToSymbol(currency);

  const updateChartData = (val) => {
    dispatch(setChartDataType(val));
  };

  return (
    <Results
      timeRange={timeRange}
      timeUnit={timeUnit}
      chartData={chartData}
      startingCosts={startingCosts}
      wordForUsers={wordForUsers}
      updateChartData={updateChartData}
      chartDataType={chartDataType}
      profitZeroString={profitZeroString}
      profit={profit}
      users={users}
      fundingNeeded={fundingNeeded}
      customerLifeTime={customerLifeTime}
      customerLifeTimeValue={customerLifeTimeValue}
      currencySymbol={currencySymbol}
    />
  );
};

export default ResultsContainer;
