import React from "react";
import PropTypes from "prop-types";
import MetricSlider from "./Slider";
import posthog from "posthog-js"; // Add this import
import { percentage, compactNumber } from "../lib/formats";
// import ExportToCrunchableButtonContainer from "../containers/ExportToCrunchableButtonContainer";

let updatedMax;

const Controls = (props) => {
  const {
    changeMax,
    changeMaxMode,
    churn,
    fixedCosts,
    growth,
    price,
    startingCosts,
    startingUsers,
    updateChurn,
    updateFixedCosts,
    updateGrowth,
    updatePrice,
    updateStartingCosts,
    updateStartingUsers,
    updateVariableCosts,
    variableCosts,
    max,
    isChangeMaxModeActive,
    sliderTitles,
    currencySymbol,
  } = props;

  const openChangeMaxMode = () => {
    updatedMax = { ...max };

    changeMaxMode(true);

    posthog.capture("click-change-sliders");
  };

  const updateMax = (variable, newMax, title) => {
    updatedMax[variable] = newMax;

    posthog.capture("change-slider-range", {
      sliderTitle: title,
      slider: newMax,
    });
  };

  const saveMax = () => {
    changeMaxMode(false);

    changeMax(updatedMax);

    posthog.capture("save-change-sliders");
  };

  const cancelMax = () => {
    changeMaxMode(false);

    posthog.capture("cancel-change-sliders");
  };

  let button;

  if (isChangeMaxModeActive) {
    button = (
      <div className="columns mt-2">
        <div className="column">
          <button
            type="button"
            onClick={cancelMax}
            className="button w-100 mt-4"
          >
            Cancel
          </button>
        </div>
        <div className="column">
          <button
            type="button"
            onClick={saveMax}
            className="button has-bg-blue w-100 mt-4"
          >
            Save
          </button>
        </div>
      </div>
    );
  } else {
    button = (
      <div className="columns mt-3 mb-3 is-vcentered">
        <div className="column is-two-fifths">
          <p className="text-align-center-mobile is-size-13 has-text-weight-bold has-text-grey-label">
            For more control and precision:
          </p>
        </div>
        <div className="column is-three-fifths">
          <button
            type="button"
            onClick={openChangeMaxMode}
            className="button has-text-centered m-auto is-block w-100"
          >
            Change slider range
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="order-1 order-lg-0 align-self-start controls-container">
      <div className="columns">
        <div className="column is-three-quarters columns no-margin-mobile">
          <div className="column revenue slider-group mr-3 no-margin-mobile">
            <h4 className="slider-group-title has-text-grey-light is-family-secondary is-size-4 has-text-centered">
              Revenue
            </h4>

            <MetricSlider
              title={sliderTitles[0].title}
              tooltip={sliderTitles[0].toolTip}
              metric={startingUsers}
              onChange={updateStartingUsers}
              max={max.startingUsers}
              format={compactNumber}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="startingUsers"
              positive
            />

            <MetricSlider
              title={sliderTitles[1].title}
              tooltip={sliderTitles[1].toolTip}
              metric={price}
              onChange={updatePrice}
              max={max.price}
              format={compactNumber}
              currencySymbol={currencySymbol}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="price"
              positive
            />

            <MetricSlider
              title={sliderTitles[2].title}
              tooltip={sliderTitles[2].toolTip}
              metric={growth}
              onChange={updateGrowth}
              max={max.growth}
              format={percentage}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="growth"
              positive
            />

            <MetricSlider
              title={sliderTitles[3].title}
              tooltip={sliderTitles[3].toolTip}
              metric={churn}
              onChange={updateChurn}
              max={max.churn}
              format={percentage}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="churn"
            />
          </div>

          <div className="column costs slider-group ml-3 no-margin-mobile">
            <h4 className="slider-group-title has-text-grey-light is-family-secondary is-size-4 has-text-centered">
              Costs
            </h4>

            <MetricSlider
              title={sliderTitles[4].title}
              tooltip={sliderTitles[4].toolTip}
              metric={variableCosts}
              onChange={updateVariableCosts}
              max={max.variableCosts}
              format={compactNumber}
              currencySymbol={currencySymbol}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="variableCosts"
            />

            <MetricSlider
              title={sliderTitles[5].title}
              tooltip={sliderTitles[5].toolTip}
              metric={fixedCosts}
              onChange={updateFixedCosts}
              max={max.fixedCosts}
              min={1}
              format={compactNumber}
              currencySymbol={currencySymbol}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="fixedCosts"
            />

            <MetricSlider
              title={sliderTitles[6].title}
              tooltip={sliderTitles[6].toolTip}
              metric={startingCosts}
              onChange={updateStartingCosts}
              max={max.startingCosts}
              format={compactNumber}
              currencySymbol={currencySymbol}
              isChangeMaxModeActive={isChangeMaxModeActive}
              updateMax={updateMax}
              variable="startingCosts"
            />

            {button}
          </div>
        </div>
        <div className="column is-flex">
          {/* <ExportToCrunchableButtonContainer /> */}
        </div>
      </div>
    </div>
  );
};

Controls.propTypes = {
  churn: PropTypes.number.isRequired,
  fixedCosts: PropTypes.number.isRequired,
  growth: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  startingUsers: PropTypes.number.isRequired,
  variableCosts: PropTypes.number.isRequired,
  startingCosts: PropTypes.number.isRequired,
  max: PropTypes.objectOf(PropTypes.number).isRequired,
  sliderTitles: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  updateChurn: PropTypes.func.isRequired,
  updateFixedCosts: PropTypes.func.isRequired,
  updateGrowth: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
  updateStartingCosts: PropTypes.func.isRequired,
  updateStartingUsers: PropTypes.func.isRequired,
  updateVariableCosts: PropTypes.func.isRequired,
  isChangeMaxModeActive: PropTypes.bool.isRequired,
  changeMax: PropTypes.func.isRequired,
  changeMaxMode: PropTypes.func.isRequired,
};

export default Controls;
