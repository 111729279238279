import React from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

library.add(faLinkedin, faTwitter, faWhatsapp);

const ShareButtons = ({
  handleTwitterShare,
  handleLinkedinShare,
  handleWhatsappShare,
}) => {
  return (
    <div className="m-auto">
      <p className="has-text-centered mt-3 mb-0">Share your startup destiny</p>
      <div className="share-buttons-container columns is-mobile">
        <div className="column hover-pointer has-text-left">
          <div
            role="button"
            tabIndex="0"
            className="share-button plausible-event-name=Share+LinkedIn"
            onClick={handleLinkedinShare}
          >
            <FontAwesomeIcon
              className="share-icon"
              size="lg"
              icon={["fab", "linkedin"]}
            />
          </div>
        </div>
        <div className="column hover-pointer has-text-centered">
          <div
            role="button"
            tabIndex="0"
            className="share-button plausible-event-name=Share+Twitter"
            onClick={handleTwitterShare}
          >
            <FontAwesomeIcon
              className="share-icon"
              size="lg"
              icon={["fab", "twitter"]}
            />
          </div>
        </div>
        <div className="column hover-pointer has-text-right">
          <div
            role="button"
            tabIndex="0"
            className="share-button plausible-event-name=Share+Whatsapp"
            onClick={handleWhatsappShare}
          >
            <FontAwesomeIcon
              className="share-icon"
              size="lg"
              icon={["fab", "whatsapp"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ShareButtons.propTypes = {
  handleTwitterShare: PropTypes.func.isRequired,
  handleLinkedinShare: PropTypes.func.isRequired,
  handleWhatsappShare: PropTypes.func.isRequired,
};

export default ShareButtons;
