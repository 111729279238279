export const primaryColor = "#3300ff";
export const green = "#b8e986";
export const red = "#ff586d";
export const grey = "#e9e9e9";
export const orange = "#FF951A";
export const purple = "#4F1E9A";
export const blue = "#1DC0FE";
export const blueInput = "#6AC5E2";
export const sliderRail = "#0F0F0F";
export const sliderTrack = "linear-gradient(-90deg, #D2E7ED 0%, #6EDEFF 100%)";
export const greyButton = "#292929";
export const greyButtonBackground = "#414447";
export const greyChartLine = "#181818";
export const white = "#FFFFFF";
