import React from "react";
import PropTypes from "prop-types";

export const ExportForm = (props) => {
  const { handleSubmit, handleInputChange, name, email, emailConsent } = props;

  return (
    <form id="exportForm" className="export-form mt-6" onSubmit={handleSubmit}>
      <input
        name="name"
        placeholder="Name"
        className="full-width-input"
        onChange={handleInputChange}
        value={name}
        required
      />
      <input
        name="email"
        placeholder="Email"
        className="full-width-input"
        onChange={handleInputChange}
        value={email}
        required
      />
      <div className="w-100 has-text-left is-size-16">
        <input
          className="styled-checkbox"
          name="emailConsent"
          id="styled-checkbox-2"
          type="checkbox"
          defaultChecked={false}
          value={emailConsent}
          onChange={handleInputChange}
        />
        <label htmlFor="styled-checkbox-2">
          <span>Yes, please send me product updates</span>
        </label>
      </div>
      <input
        type="submit"
        value="Export"
        className="button has-bg-orange is-size-5 export-form-button mt-5"
      />
    </form>
  );
};

ExportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailConsent: PropTypes.bool.isRequired,
};
