import React from "react";
import { useSelector } from "react-redux";
import ShareButtons from "../components/ShareButtons";
import { getData } from "../lib/calculators/getData";
import { currencyToSymbol } from "../lib/currencySymbol";
import { compactNumber } from "../lib/formats";
import { getTwitterShareUrl, getWhatsappShareUrl } from "../lib/getShareUrls";
import { LINKEDIN_SHARE_URL } from "../lib/const";
import { usePostHog } from "posthog-js/react";

const ShareButtonsContainer = () => {
  const {
    churn,
    fixedCosts,
    growth,
    price,
    startingUsers,
    variableCosts,
    timeRange,
    startingCosts,
    currency,
  } = useSelector((state) => state.app);

  const getShareableData = () => {
    const { users, fundingNeeded, profitZeroString } = getData(
      churn,
      fixedCosts,
      growth,
      price,
      startingCosts,
      startingUsers,
      timeRange,
      "year",
      variableCosts
    );

    const currencySymbol = currencyToSymbol(currency);

    const fundingNeededString =
      fundingNeeded >= 0
        ? currencySymbol + compactNumber(fundingNeeded)
        : "Infinite";
    const usersInYearFive = compactNumber(users[5]);

    return {
      profitZeroString,
      fundingNeededString,
      usersInYearFive,
    };
  };

  const posthog = usePostHog();

  const handleLinkedinShare = () => {
    window.open(LINKEDIN_SHARE_URL, "_blank");

    posthog?.capture("linkedinShare");
  };

  const handleTwitterShare = () => {
    const data = getShareableData();

    const twitterShareUrl = getTwitterShareUrl(data);

    window.open(twitterShareUrl, "_blank");

    posthog?.capture("twitterShare");
  };

  const handleWhatsappShare = () => {
    const data = getShareableData();

    const whatsappShareUrl = getWhatsappShareUrl(data);

    window.open(whatsappShareUrl, "_blank");

    posthog?.capture("whatsappShare");
  };

  return (
    <ShareButtons
      handleLinkedinShare={handleLinkedinShare}
      handleTwitterShare={handleTwitterShare}
      handleWhatsappShare={handleWhatsappShare}
    />
  );
};

export default ShareButtonsContainer;
