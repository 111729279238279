import stateMultiplier from "./stateMultiplier";

const yearsToMonths = (yearState) => {
  console.log("Converting years to months");
  const multipliedState = stateMultiplier(yearState, 1 / 12);

  const monthState = {
    ...multipliedState,
  };

  console.log("Month state:", monthState);
  return monthState;
};

const yearsToWeeks = (yearState) => {
  const multipliedState = stateMultiplier(yearState, 1 / 52);

  const weekState = {
    ...multipliedState,
  };

  return weekState;
};

const monthsToWeeks = (monthState) => {
  const multipliedState = stateMultiplier(monthState, 1 / (52 / 12));

  const weekState = {
    ...multipliedState,
  };

  return weekState;
};

const monthsToYears = (monthState) => {
  const multipliedState = stateMultiplier(monthState, 12);

  const yearState = {
    ...multipliedState,
  };

  return yearState;
};

const weeksToYears = (weekState) => {
  const multipliedState = stateMultiplier(weekState, 52);

  const yearState = {
    ...multipliedState,
  };

  return yearState;
};

const weeksToMonths = (weekState) => {
  const multipliedState = stateMultiplier(weekState, 52 / 12);

  const monthState = {
    ...multipliedState,
  };

  return monthState;
};

export {
  yearsToMonths,
  yearsToWeeks,
  monthsToYears,
  monthsToWeeks,
  weeksToYears,
  weeksToMonths,
};
