/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
import React from "react";
import { compactNumber } from "../lib/formats";
import { orange, blue, purple } from "../lib/colors";
import { UNICORN, SNAKE, SKULL } from "../lib/const";

const Scenario = (props) => {
  const {
    profit,
    users,
    profitZeroString,
    timeUnit,
    timeRange,
    fundingNeeded,
    customerLifeTime,
    customerLifeTimeValue,
    currencySymbol,
  } = props;

  let scenarioColorClass;
  let scenarioImageSrc;

  if (profit > 0) {
    scenarioColorClass = blue;
    scenarioImageSrc = SNAKE;
  } else {
    scenarioColorClass = purple;
    scenarioImageSrc = SKULL;
  }

  switch (timeUnit) {
    case "year":
      if (profit > 1000000) {
        scenarioColorClass = orange;
        scenarioImageSrc = UNICORN;
      }
      break;

    case "month":
      if (profit > 83333) {
        scenarioColorClass = orange;
        scenarioImageSrc = UNICORN;
      }
      break;

    case "week":
      if (profit > 19231) {
        scenarioColorClass = orange;
        scenarioImageSrc = UNICORN;
      }
      break;

    default:
      scenarioColorClass = blue;
      scenarioImageSrc = SNAKE;
  }

  return (
    <div className="scenario-container has-text-centered">
      <div
        className="scenario-title is-family-secondary is-size-5"
        style={{ backgroundColor: scenarioColorClass }}
      >
        Your destiny
      </div>
      <div
        className="scenario-content has-text-white"
        style={{ borderColor: scenarioColorClass }}
      >
        <p className="profit-string is-family-secondary ">{profitZeroString}</p>
        <div
          className="scenario-destiny"
          style={{ borderColor: scenarioColorClass }}
        >
          <div className="scenario-icon-container">
            <img src={scenarioImageSrc} alt="snake" className="scenario-icon" />
          </div>
          <div className="scenario-summary is-inline-block">
            <p className="is-family-secondary mb-2">
              {timeUnit} {timeRange}:
            </p>
            <div className="scenario-metric">
              <p className="mb-2">
                <strong>{compactNumber(users)}</strong>
                <span className="is-size-12"> users</span>
              </p>
              <p>
                <strong>{currencySymbol + compactNumber(profit)}</strong>
                <span className="is-size-12">
                  {" "}
                  {profit >= 0 ? "profit" : "loss"}
                </span>
              </p>
            </div>
          </div>
        </div>

        <p className="scenario-metric mt-3 mb-2">
          <strong>
            {fundingNeeded >= 0
              ? currencySymbol + compactNumber(fundingNeeded)
              : "Infinite"}
          </strong>
          <span className="is-size-12">&nbsp;funding needed</span>
        </p>

        <p className="scenario-metric mb-2 ">
          <strong>
            {isFinite(customerLifeTimeValue)
              ? currencySymbol + compactNumber(customerLifeTimeValue)
              : "Infinite"}
          </strong>
          <span className="is-size-12">&nbsp;customer lifetime value</span>
        </p>

        <p className="scenario-metric mb-2">
          <strong>
            {isFinite(customerLifeTime)
              ? compactNumber(customerLifeTime)
              : "Infinite"}{" "}
            {timeUnit}s
          </strong>
          <span className="is-size-12">&nbsp;average retention</span>
        </p>
      </div>
    </div>
  );
};

export default Scenario;
