const stateMultiplier = (state, m) => {
  const newState = {
    ...state,
    growth: state.growth * m,
    churn: state.churn * m,
    startingUsers: state.startingUsers,
    price: state.price * m,
    variableCosts: state.variableCosts * m,
    fixedCosts: state.fixedCosts * m,
    startingCosts: state.startingCosts,
    timeRange: state.timeRange / m,
    max: {
      startingUsers: state.max.startingUsers,
      price: state.max.price * m,
      growth: state.max.growth * m,
      churn: state.max.churn * m,
      variableCosts: state.max.variableCosts * m,
      fixedCosts: state.max.fixedCosts * m,
      startingCosts: state.max.startingCosts,
    },
  };

  return newState;
};

export default stateMultiplier;
