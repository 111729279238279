export const STARTING_USERS = 'STARTING_USERS';
export const PRICE = 'PRICE';
export const GROWTH = 'GROWTH';
export const CHURN = 'CHURN';
export const VARIABLE_COSTS = 'VARIABLE_COSTS';
export const FIXED_COSTS = 'FIXED_COSTS';
export const STARTING_COSTS = 'STARTING_COSTS';
export const CHANGE_MAX = 'CHANGE_MAX';
export const CHANGE_MAX_MODE = 'CHANGE_MAX_MODE';


export const updateStartingUsers = (e) => {
  return {
    type: STARTING_USERS,
    startingUsers: e,
  };
};

export const updatePrice = (e) => {
  return {
    type: PRICE,
    price: e,
  };
};

export const updateGrowth = (e) => {
  return {
    type: GROWTH,
    growth: e,
  };
};

export const updateChurn = (e) => {
  return {
    type: CHURN,
    churn: e,
  };
};

export const updateVariableCosts = (e) => {
  return {
    type: VARIABLE_COSTS,
    variableCosts: e,
  };
};

export const updateFixedCosts = (e) => {
  return {
    type: FIXED_COSTS,
    fixedCosts: e,
  };
};

export const updateStartingCosts = (e) => {
  return {
    type: STARTING_COSTS,
    startingCosts: e,
  };
};

export const changeMax = (max) => {
  return {
    type: CHANGE_MAX,
    max,
  };
};

export const changeMaxMode = (isChangeMaxModeActive) => {
  return {
    type: CHANGE_MAX_MODE,
    isChangeMaxModeActive,
  };
};
