/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { white } from "../lib/colors";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { loading } = this.state;

    return (
      <BeatLoader
        className={override}
        sizeUnit="px"
        size="15"
        color={white}
        loading={loading}
      />
    );
  }
}

export default Spinner;
