import { usePostHog } from "posthog-js/react";

export const Header = () => {
  const posthog = usePostHog();

  const handleCtaClick = () => {
    posthog?.capture("surveyClick");
  };

  return (
    <header className="header-bg">
      <div className="container header-container">
        <div className="column crunchable-description is-pulled-left">
          <h1>
            We are close to finishing the successor to StartupCalculator:
            Crunchable
          </h1>
          <p>
            Crunchable is leaps ahead of Startup Calculator, leveraging AI it
            models your business and answers your what-if questions while
            generating accountancy level financial documents.
          </p>
        </div>
        <div className="column is-pulled-left crunchable-cta">
          <a
            href="https://survey.startupcalculator.co"
            target="_blank"
            className="crunchable-survey-button"
            onClick={handleCtaClick}
          >
            Help shape Crunchable
          </a>
        </div>
        <div className="header-logo is-pulled-right">
          <a href="https://crunchable.app" target="_blank">
            <img src="img/crunchable__logo.svg" alt="crunchable logo" />
          </a>
        </div>
      </div>
    </header>
  );
};
