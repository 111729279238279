import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { blueInput } from "../lib/colors";

library.add(faCaretUp, faCaretDown);

export const Switches = ({
  timeUnit,
  model,
  currency,
  handleTimeUnitChange,
  handleModelChange,
  handleCurrencyChange,
  modelOptions,
  timeOptions,
  currencyOptions,
}) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon
            icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}
          />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className="order-1 align-self-start">
      <div className="d-block">
        <p className="is-size-5 is-family-secondary mt-5">Time unit</p>
        <Select
          components={{ DropdownIndicator }}
          className="react-select-container plausible-event-name=Change+Time+Unit"
          classNamePrefix="react-select"
          options={timeOptions}
          value={timeOptions.find((option) => option.value === timeUnit)}
          onChange={handleTimeUnitChange}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: blueInput,
            },
          })}
        />
      </div>

      <div className="d-block mb-2">
        <p className="is-size-5 is-family-secondary mt-5">Business model</p>
        <Select
          components={{ DropdownIndicator }}
          className="react-select-container plausible-event-name=Change+Business+Model"
          classNamePrefix="react-select"
          options={modelOptions}
          value={modelOptions.find((option) => option.value === model)}
          onChange={handleModelChange}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: blueInput,
            },
          })}
        />
      </div>

      <div className="d-block mb-2">
        <p className="is-size-5 is-family-secondary mt-5">Currency</p>
        <Select
          components={{ DropdownIndicator }}
          className="react-select-container plausible-event-name=Change+Currency"
          classNamePrefix="react-select"
          options={currencyOptions}
          value={currencyOptions.find((option) => option.value === currency)}
          onChange={handleCurrencyChange}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: blueInput,
            },
          })}
        />
      </div>
    </div>
  );
};

Switches.propTypes = {
  timeUnit: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  handleTimeUnitChange: PropTypes.func.isRequired,
  handleModelChange: PropTypes.func.isRequired,
  handleCurrencyChange: PropTypes.func.isRequired,
  modelOptions: PropTypes.array.isRequired,
  timeOptions: PropTypes.array.isRequired,
  currencyOptions: PropTypes.array.isRequired,
};
