const cumulativeProfitCalc = (profit) => {
  const cumulativeProfit = [];

  cumulativeProfit.push(profit[0]);

  for (let i = 1; i < profit.length; i += 1) {
    cumulativeProfit.push(cumulativeProfit[i - 1] + profit[i]);
  }

  return cumulativeProfit;
};

export default cumulativeProfitCalc;
