import numeral from "numeral";

export const compactNumber = (value) => {
  if (Math.abs(value) < 10) {
    return `${numeral(value).format("0.0")}`;
  }
  if (Math.abs(value) < 1000) {
    return `${numeral(value).format("0")}`;
  }
  if (Math.abs(value) < 10000) {
    return `${numeral(value).format("0").toUpperCase()}`;
  }
  if (Math.abs(value) < 1000000) {
    return `${numeral(value).format("0a").toUpperCase()}`;
  }
  if (Math.abs(value) < 10000000) {
    return `${numeral(value).format("0.0a").toUpperCase()}`;
  }
  return `${numeral(value).format("0a").toUpperCase()}`;
};

export const percentage = (value) => {
  if (Math.abs(value) < 100) {
    return `${numeral(value).format("0.0")}%`;
  }
  return `${numeral(value).format("0")}%`;
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== "string" || string.length === 0) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const roundToTen = (value) => {
  return Math.ceil(value / 10) * 10;
};
