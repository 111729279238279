import React, { useEffect, useRef } from "react";
import { renderChart, destroyChart } from "../lib/chartConfiguration";

const Chart = ({ dataLeft, dataRight, timeUnit, currencySymbol }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = renderChart(dataLeft, dataRight, timeUnit, currencySymbol);

    return () => {
      destroyChart(chart);
    };
  }, [dataLeft, dataRight, timeUnit, currencySymbol]);

  return <canvas ref={chartRef} id="myChart" />;
};

export default Chart;
