import { createSlice } from "@reduxjs/toolkit";
import {
  yearsToMonths,
  yearsToWeeks,
  monthsToYears,
  monthsToWeeks,
  weeksToYears,
  weeksToMonths,
} from "./lib/calculators/timeConverters";
import { YEAR, MONTH, WEEK, SUBSCRIPTION, EUR, CUMULATIVE } from "./lib/const";

export const initialState = {
  churn: 40,
  timeUnit: YEAR, // Make sure this is set
  timeRange: 5,
  price: 675,
  fixedCosts: 120000,
  growth: 100,
  model: SUBSCRIPTION, // Make sure this is set
  currency: EUR, // Make sure this is set
  chartDataType: CUMULATIVE,
  startingCosts: 250000,
  startingUsers: 130,
  variableCosts: 400,
  max: {
    churn: 100,
    fixedCosts: 1000000,
    growth: 500,
    price: 2500,
    startingCosts: 1000000,
    startingUsers: 1000,
    variableCosts: 2500,
  },
  isChangeMaxModeActive: false,
  exporter: {
    isOpen: false,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setChurn: (state, action) => {
      state.churn = action.payload;
    },
    setTimeUnit: (state, action) => {
      state.timeUnit = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setTimeRange: (state, action) => {
      state.timeRange = action.payload;
    },
    setFixedCosts: (state, action) => {
      state.fixedCosts = action.payload;
    },
    setGrowth: (state, action) => {
      state.growth = action.payload;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setStartingCosts: (state, action) => {
      state.startingCosts = action.payload;
    },
    setChartDataType: (state, action) => {
      state.chartDataType = action.payload;
    },
    setStartingUsers: (state, action) => {
      state.startingUsers = action.payload;
    },
    setVariableCosts: (state, action) => {
      state.variableCosts = action.payload;
    },
    setMax: (state, action) => {
      state.max = { ...state.max, ...action.payload };
    },
    setChangeMaxModeActive: (state, action) => {
      state.isChangeMaxModeActive = action.payload;
    },
    changeUnit: (state, action) => {
      const { timeUnit, oldTimeUnit } = action.payload;

      let newState;

      if (timeUnit === oldTimeUnit) {
        return;
      }

      switch (timeUnit) {
        case YEAR:
          if (oldTimeUnit === MONTH) {
            newState = monthsToYears(state);
          } else if (oldTimeUnit === WEEK) {
            newState = weeksToYears(state);
          }
          break;
        case MONTH:
          if (oldTimeUnit === YEAR) {
            newState = yearsToMonths(state);
          } else if (oldTimeUnit === WEEK) {
            newState = weeksToMonths(state);
          }
          break;
        case WEEK:
          if (oldTimeUnit === YEAR) {
            newState = yearsToWeeks(state);
          } else if (oldTimeUnit === MONTH) {
            newState = monthsToWeeks(state);
          }
          break;
        default:
          return;
      }

      if (newState) {
        newState.timeUnit = timeUnit;

        // Update the state with the new values
        Object.keys(newState).forEach((key) => {
          state[key] = newState[key];
        });
      }
    },
    changeModel: (state, action) => {
      state.model = action.payload;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
    },
    exporterOpen: (state) => {
      state.exporter.isOpen = true;
    },
    exporterClose: (state) => {
      state.exporter.isOpen = false;
    },
  },
});

export const {
  setChurn,
  setTimeUnit,
  setPrice,
  setFixedCosts,
  setGrowth,
  setModel,
  setCurrency,
  setChartDataType,
  setStartingCosts,
  setStartingUsers,
  setTimeRange,
  setVariableCosts,
  setMax,
  setChangeMaxModeActive,
  changeUnit,
  changeModel,
  changeCurrency,
  exporterOpen,
  exporterClose,
} = appSlice.actions;

export default appSlice.reducer;
