import React from "react";
import { useSelector } from "react-redux";
import ControlsContainer from "../containers/ControlsContainer";
import ResultsContainer from "../containers/ResultsContainer";
import ExporterContainer from "../containers/ExporterContainer";
import SwitchesContainer from "../containers/SwitchesContainer";
import AdviceContainer from "../containers/AdviceContainer";
import { Footer } from "./Footer";
import ErrorBoundary from "./ErrorBoundary";
import { Header } from "./Header";

const Main = () => {
  return (
    <ErrorBoundary>
      <div className="app has-background-grey-darker">
        <Header />
        <div className="app-bg no-padding-top-mobile">
          <img
            className="bg-img-top"
            src="img/starsBg.svg"
            alt="stars-background"
          />
          <img
            className="bg-img-right-corner"
            src="img/skullBg.svg"
            alt="skull-background"
          />
          <div className="container-bg">
            <div className="container">
              <div className="columns no-margin">
                <div className="left-sidebar column is-one-quarter is-one-fifth-huge is-hidden-touch">
                  <div className="side-components-wrapper">
                    <img
                      className="app-logo"
                      src="/img/startup-calculator-logo.svg"
                      width="283"
                      alt="startup calculator logo"
                    />
                    <SwitchesContainer />
                    <div className="mt-5 advice-wrapper">
                      <AdviceContainer />
                    </div>
                  </div>
                </div>
                <div className="column is-three-quarter pt-4 mb-5 no-padding-top-mobile">
                  <ResultsContainer />
                  <ControlsContainer />
                </div>
              </div>
              {/* <ExporterContainer /> */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default Main;
