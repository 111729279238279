import { getUsers, getNonCumulativeUsers } from './users';
import profitZeroCalc from './profitZero';
import profitZeroStringCalc from './profitZeroString';
import cumulativeProfitCalc from './cumulativeProfit';
import fundingNeededCalc from './fundingNeeded';
import { getCustomerLifeTime, getCustomerLifeTimeValue } from './customerLifeTimeValue';
import profitCalc from './profit';

export const getData = (
  churn,
  fixedCosts,
  growth,
  price,
  startingCosts,
  startingUsers,
  timeRange,
  timeUnit,
  variableCosts,
) => {
  const users = getUsers(
    churn,
    growth,
    startingUsers,
    timeRange,
  );

  const nonCumulativeUsers = getNonCumulativeUsers(
    timeRange,
    users,
  );

  const profit = profitCalc(
    fixedCosts,
    price,
    startingCosts,
    timeRange,
    users,
    variableCosts,
  );

  const cumulativeProfit = cumulativeProfitCalc(profit);

  const fundingNeeded = fundingNeededCalc(
    churn,
    fixedCosts,
    growth,
    price,
    startingCosts,
    startingUsers,
    timeRange,
    variableCosts,
  );

  const profitZero = profitZeroCalc(
    churn,
    fixedCosts,
    growth,
    price,
    startingUsers,
    variableCosts,
  );

  const profitZeroString = profitZeroStringCalc(profit, profitZero, timeUnit);

  const customerLifeTime = getCustomerLifeTime(churn);

  const customerLifeTimeValue = getCustomerLifeTimeValue(customerLifeTime, price, variableCosts);

  return ({
    users,
    nonCumulativeUsers,
    profit,
    cumulativeProfit,
    fundingNeeded,
    profitZero,
    profitZeroString,
    customerLifeTime,
    customerLifeTimeValue,
  });
};
