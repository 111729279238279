import { usePostHog } from "posthog-js/react";

export const adviceArray = [];

export const addAdviceToArray = (advice) => {
  let isAdviceNew = true;
  const posthog = usePostHog();

  for (let i = 0; i < adviceArray.length; i += 1) {
    if (adviceArray[i] === advice) {
      isAdviceNew = false;
      break;
    }
  }

  if (isAdviceNew) {
    adviceArray.unshift(advice);

    posthog?.capture("newAdvice", { adviceTitle: advice.title });
  }
};

export const removeAdviceFromArray = (advice) => {
  let isAdviceInArray = false;
  let advicePositionInArray;

  for (let i = 0; i < adviceArray.length; i += 1) {
    if (adviceArray[i] === advice) {
      isAdviceInArray = true;
      advicePositionInArray = i;
      break;
    }
  }

  if (isAdviceInArray) adviceArray.splice(advicePositionInArray, 1);
};
