/* eslint-disable no-restricted-globals */
import { getUsers } from './users';
import profitCalc from './profit';
import cumulativeProfitCalc from './cumulativeProfit';
import profitZeroCalc from './profitZero';

const fundingNeededCalc = (
  churn,
  fixedCosts,
  growth,
  price,
  startingCosts,
  startingUsers,
  time,
  variableCosts,
) => {
  const m = time / 260;

  const newChurn = churn * m;
  const newFixedCosts = fixedCosts * m;
  const newGrowth = growth * m;
  const newPrice = price * m;
  const newVariableCosts = variableCosts * m;

  const profitZero = profitZeroCalc(
    newChurn,
    newFixedCosts,
    newGrowth,
    newPrice,
    startingUsers,
    newVariableCosts,
  );

  const nanToZero = (n) => {
    if (isNaN(n)) {
      return 1;
    }

    return n;
  };

  let profitZeroMax = (
    Math.round(
      Math.max(
        nanToZero(profitZero),
      ),
    )
  );

  profitZeroMax = isFinite(profitZeroMax) ? profitZeroMax : 1800;

  const users = getUsers(
    newChurn,
    newGrowth,
    startingUsers,
    profitZeroMax,
  );

  const profit = profitCalc(
    newFixedCosts,
    newPrice,
    startingCosts,
    profitZeroMax,
    users,
    newVariableCosts,
  );

  const cumulativeProfit = cumulativeProfitCalc(profit);

  let fundingNeeded;

  if (profitZero < 0) {
    fundingNeeded = 0;
  } else {
    fundingNeeded = 0 - cumulativeProfit[Math.round(profitZero)];
  }

  return fundingNeeded;
};

export default fundingNeededCalc;
