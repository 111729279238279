/* eslint-disable react/prop-types */
import React from "react";

export const Exporter = (props) => {
  const { content, error, modalClassName, closeForm } = props;

  return (
    <div className={modalClassName}>
      <div
        role="button"
        className="modal-background"
        onClick={closeForm}
        tabIndex={0}
      />
      <div className="modal-content rounded-box has-text-centered has-text-white">
        <h2 className="is-size-5 mb-1 is-family-secondary">
          Export to Crunchable
        </h2>
        <p className="exporter-subtitle has-text-light">
          Get a brand new Google Sheet with all the data and charts in your
          inbox
        </p>

        {content}
        {error}
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={closeForm}
        tabIndex={0}
      />
    </div>
  );
};
