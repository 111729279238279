/* eslint-disable max-len */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/exporter";
// import tagManager from "../lib/googleTagManager";
import { ExportForm } from "../components/ExportForm";
import { validateEmail } from "../lib/validator";
import { postToZapier } from "../lib/zapier";
import Spinner from "../components/Spinner";
import { Exporter } from "../components/Exporter";
import Emoji from "../components/Emoji";
import { monthsToYears, weeksToYears } from "../lib/calculators/timeConverters";
import { ECOMMERCE_TEMPLATE, SUBSCRIPTION_TEMPLATE } from "../lib/const";

class ExporterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "form",
      name: "",
      email: "",
      emailConsent: false,
      isEmailValid: true,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { name, email, emailConsent, content, isEmailValid } = this.state;

    const { isOpen, closeExporter, timeUnit } = this.props;

    let zapierState;

    switch (timeUnit) {
      case "month":
        zapierState = monthsToYears(this.props);
        break;
      case "week":
        zapierState = weeksToYears(this.props);
        break;
      default:
        zapierState = { ...this.props };
        break;
    }

    const handleSubmit = (e) => {
      const emailValid = validateEmail(email);

      const exportTemplateId =
        zapierState.model === "e-commerce"
          ? ECOMMERCE_TEMPLATE
          : SUBSCRIPTION_TEMPLATE;

      const data = {
        zapierState,
        name,
        email,
        emailConsent,
        exportTemplateId,
      };

      const sendSuccess = () => {
        this.setState({
          content: "sendConfirmation",
        });

        // tagManager({ event: "export-success" });
      };

      const sendError = () => {
        this.setState({
          content: "sendErrorMessage",
        });

        // tagManager({ event: "export-error" });
      };

      if (emailValid) {
        this.setState({
          content: "spinner",
          isEmailValid: true,
        });

        postToZapier(data, sendSuccess, sendError);
      } else {
        this.setState({
          isEmailValid: false,
        });
      }
      e.preventDefault();
    };

    const resetForm = () => {
      this.setState({
        content: "form",
      });
    };

    const closeForm = () => {
      closeExporter();
      setTimeout(resetForm, 1000); // Timeout to wait for modal animation to close before resetting form
    };

    const spinner = (
      <div className="spinner my-5">
        <Spinner />
      </div>
    );

    const exportForm = (
      <ExportForm
        name={name}
        email={email}
        emailConsent={emailConsent}
        handleInputChange={this.handleInputChange}
        handleSubmit={handleSubmit}
      />
    );

    const emailValidError = (
      <p className="form-message mt-3">Please fill in a valid email address</p>
    );

    const sendErrorMessage = (
      <p className="form-message my-5">
        Oh oh.. Something went wrong. Please contact{" "}
        <a href="mailto:mail@themainingredient.co">mail@themainingredient.co</a>{" "}
        if the error persists.
      </p>
    );

    const sendConfirmation = (
      <div id="sendConfirmation" className="form-message">
        <p className="my-5">
          Your export should be with you shortly {<Emoji symbol="😎" />}
        </p>
        <button
          type="button"
          onClick={closeForm}
          className="button has-bg-orange w-100 mt-4 mb-2"
        >
          Close this window
        </button>
      </div>
    );

    let exporterContent;
    let error;

    switch (content) {
      case "form":
        exporterContent = exportForm;
        break;
      case "spinner":
        exporterContent = spinner;
        error = "";
        break;
      case "sendConfirmation":
        exporterContent = sendConfirmation;
        break;
      case "sendErrorMessage":
        exporterContent = sendErrorMessage;
        break;
      default:
        exporterContent = exportForm;
    }

    error = !isEmailValid ? emailValidError : "";

    const modalClassName = isOpen ? "modal is-active" : "modal";

    return (
      <Exporter
        content={exporterContent}
        error={error}
        modalClassName={modalClassName}
        closeForm={closeForm}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    churn: state.controls.churn,
    fixedCosts: state.controls.fixedCosts,
    growth: state.controls.growth,
    price: state.controls.price,
    startingUsers: state.controls.startingUsers,
    users: state.controls.users,
    variableCosts: state.controls.variableCosts,
    timeUnit: state.switches.timeUnit,
    timeRange: state.switches.timeRange,
    startingCosts: state.controls.startingCosts,
    model: state.switches.model,
    max: state.controls.max,
    isOpen: state.exporter.isOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

ExporterContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeExporter: PropTypes.func.isRequired,
  timeUnit: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExporterContainer);
