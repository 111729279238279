/* eslint-disable max-len */
import { capitalizeFirstLetter } from './formats';
import { E_COMMERCE } from './const';

export const getSliderTitles = (mode, timeUnit) => {
  if (mode === E_COMMERCE) {
    return [
      {
        title: 'Launching customers',
        toolTip: 'How many customers will your startup have at the start?',
      },
      {
        title: `${capitalizeFirstLetter(timeUnit)}ly average revenue per customer`,
        toolTip: `How much will an average customer spend per ${timeUnit}?`,
      },
      {
        title: `${capitalizeFirstLetter(timeUnit)}ly customer growth`,
        toolTip: `How many new customers will you acquire per ${timeUnit} compared to the number of customers in the previous ${timeUnit}?`,
      },
      {
        title: `${capitalizeFirstLetter(timeUnit)}ly customer churn`,
        toolTip: `How many customers will stop shopping every ${timeUnit} as a percentage of your entire customer base?`,
      },
      {
        title: `${capitalizeFirstLetter(timeUnit)}ly average costs per customer`,
        toolTip: 'How much do you need to spend to generate the revenue mentioned above? This includes producing or buying the goods, acquisition and costs to serve.',
      },
      {
        title: `Fixed costs per ${timeUnit}`,
        toolTip: `How much do you need to spend per ${timeUnit} in order to stay operational?`,
      },
      {
        title: 'Starting costs',
        toolTip: 'How much do you need to spend before you can service your first user?',
      },
    ];
  }

  return [
    {
      title: 'Launching users',
      toolTip: 'How many users will your startup have at the start?',
    },
    {
      title: `Price per ${timeUnit}`,
      toolTip: `How much will your product cost per ${timeUnit}?`,
    },
    {
      title: `${capitalizeFirstLetter(timeUnit)}ly user growth`,
      toolTip: `How many new users will start using your product compared to the number of users in the previous ${timeUnit}?`,
    },
    {
      title: `${capitalizeFirstLetter(timeUnit)}ly user churn`,
      toolTip: `How many users will stop using your product every ${timeUnit} as a percentage of your entire user base?`,
    },
    {
      title: `Variable costs per user per ${timeUnit}`,
      toolTip: `How much does it cost you to service a single user per ${timeUnit}?`,
    },
    {
      title: `Fixed costs per ${timeUnit}`,
      toolTip: `How much do you need to spend per ${timeUnit} in order to stay operational?`,
    },
    {
      title: 'Starting costs',
      toolTip: 'How much do you need to spend before you can acquire your first customer?',
    },
  ];
};
