import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import "./stylesheets/app.scss";

import Main from "./components/Main";
import store from "./store";
import { POSTHOG_API_KEY } from "./lib/const";

if (process.env.NODE_ENV === "production") {
  // Initialize PostHog
  posthog.init(POSTHOG_API_KEY, {
    api_host: "https://eu.i.posthog.com", // or your self-hosted URL
  });
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PostHogProvider client={posthog}>
      <Main />
    </PostHogProvider>
  </Provider>
);
