/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../actions/index";
import Advice from "../components/Advice";
import * as advices from "../lib/advice/advices";
import {
  adviceArray,
  addAdviceToArray,
  removeAdviceFromArray,
} from "../lib/advice/adviceArray";
import { getData } from "../lib/calculators/getData";

const AdviceContainer = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.app);
  const {
    churn,
    fixedCosts,
    growth,
    price,
    startingUsers,
    variableCosts,
    timeRange,
    timeUnit,
    startingCosts,
    model,
    max,
  } = state;

  // Calculate data for charts and scenario based on slider values
  const {
    users,
    nonCumulativeUsers,
    profit,
    cumulativeProfit,
    fundingNeeded,
    profitZero,
    profitZeroString,
    customerLifeTime,
    customerLifeTimeValue,
  } = getData(
    churn,
    fixedCosts,
    growth,
    price,
    startingCosts,
    startingUsers,
    timeRange,
    timeUnit,
    variableCosts
  );

  const ifAdvice = (condition, advice) => {
    condition ? addAdviceToArray(advice) : removeAdviceFromArray(advice);
  };

  ifAdvice(churn < 2 && timeUnit === "year", advices.lowChurn);

  ifAdvice(fundingNeeded.medium <= 0, advices.noFunding);

  ifAdvice(
    startingUsers > 0.9 * max.startingUsers ||
      price > 0.9 * max.price ||
      churn > 0.9 * max.churn ||
      growth > 0.9 * max.growth ||
      variableCosts > 0.9 * max.variableCosts ||
      fixedCosts > 0.9 * max.fixedCosts ||
      startingCosts > 0.9 * max.startingCosts,
    advices.sliderMax
  );

  ifAdvice(
    startingUsers < 0.1 * max.startingUsers ||
      price < 0.1 * max.price ||
      churn < 0.1 * max.churn ||
      growth < 0.1 * max.growth ||
      variableCosts < 0.1 * max.variableCosts ||
      fixedCosts < 0.1 * max.fixedCosts ||
      startingCosts < 0.1 * max.startingCosts,
    advices.sliderMin
  );

  ifAdvice(users[5] >= 10000 && timeUnit === "year", advices.tenThousandUsers);

  ifAdvice(startingUsers === 0, advices.zeroStartingUsers);

  ifAdvice(
    (profitZero > 7 || isNaN(profitZero) || !isFinite(profitZero)) &&
      timeUnit === "year",
    advices.notProfitableWithinSevenYears
  );

  ifAdvice(variableCosts > price, advices.costsHigherThanPrice);

  ifAdvice(growth > 250 && timeUnit === "year", advices.extremeGrowth);

  return <Advice adviceArray={adviceArray} />;
};

const mapStateToProps = (state) => {
  return {
    price: state.price,
    startingUsers: state.startingUsers,
    growth: state.growth,
    users: state.users,
    churn: state.churn,
    variableCosts: state.variableCosts,
    fixedCosts: state.fixedCosts,
    profit: state.profit,
    timeUnit: state.timeUnit,
    timeRange: state.timeRange,
    chartData: state.chartData,
    startingCosts: state.startingCosts,
    model: state.model,
    scenario: state.scenario,
    fundingNeeded: state.fundingNeeded,
    cumulativeProfit: state.cumulativeProfit,
    profitZero: state.profitZero,
    max: state.max,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AdviceContainer);
