/* eslint-disable max-len */
export const getTwitterShareUrl = (data) => {
  return (
    `https://twitter.com/intent/tweet?text=My%20startup%20destiny%20is%3A%0A%20%20%20%20%E2%80%A2%20${data.profitZeroString}%0A%20%20%20%20%E2%80%A2%20${data.fundingNeededString}%20funding%20needed%0A%20%20%20%20%E2%80%A2%20${data.usersInYearFive}%20users%20after%205%20years%0A%0AFind%20out%20your%20startup%20destiny%20at%20http%3A%2F%2Fbitly.com%2Ftmistartupcalculator%0A%23mystartupdestiny`
  );
};

export const getWhatsappShareUrl = (data) => {
  return (
    `https://wa.me/?text=Hey!%20Check%20out%20my%20startup%20destiny%3A%0A%20%20%20%20%E2%80%A2%20${data.profitZeroString}%0A%20%20%20%20%E2%80%A2%20${data.fundingNeededString}%20funding%20needed%0A%20%20%20%20%E2%80%A2%20${data.usersInYearFive}%20users%20after%205%20years%0A%0AFind%20out%20your%20startup%20destiny%20at%20http%3A%2F%2Fbit.ly%2Ftmi-startupcalculator`
  );
};
