export const getCustomerLifeTime = (
  churn,
) => {
  const customerLifeTime = Math.log(2) / (churn / 100);

  return customerLifeTime;
};

export const getCustomerLifeTimeValue = (
  customerLifeTime,
  price,
  variableCosts,
) => {
  const customerLifeTimeValue = customerLifeTime * (price - variableCosts);

  return customerLifeTimeValue;
};
