/* eslint prefer-arrow-callback: "off" , quote-props:"off", arrow-parens: "off",  no-unused-vars:"off" */
import { ZAPIER_URL } from './const';

export const postToZapier = async (data, success) => {
  const url = ZAPIER_URL;

  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({ data }),
  };

  fetch(url, requestOptions)
    .then(async response => {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const returnData = isJson && await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && returnData.message) || response.status;
        return Promise.reject(error);
      }

      success(response);
    })
    .catch(error => {
      this.setState({ errorMessage: error.toString() });
      console.error('There was an error!', error);
    });
};
