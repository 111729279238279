import React from "react";
import PropTypes from "prop-types";
import Chart from "./Chart";
import Scenario from "./Scenario";
import { NON_CUMULATIVE, CUMULATIVE } from "../lib/const";
import ShareButtonsContainer from "../containers/ShareButtonsContainer";
import posthog from "posthog-js";

const Results = (props) => {
  const {
    timeRange,
    timeUnit,
    chartDataType,
    updateChartData,
    chartData,
    profitZeroString,
    profit,
    users,
    fundingNeeded,
    customerLifeTime,
    customerLifeTimeValue,
    currencySymbol,
  } = props;

  const handleChartDataChange = () => {
    const val = chartDataType === CUMULATIVE ? NON_CUMULATIVE : CUMULATIVE;

    updateChartData(val);

    posthog.capture("chartData", { chartDataType: val });
  };

  return (
    <div className="sticky-top">
      <div className="columns no-margin chart-wrapper">
        <div className="column is-three-quarter chart-container">
          <div className="chart mb-4">
            <div className="columns is-mobile no-margin-mobile">
              <div className="axis-label-container column is-2 is-pulled-left is-size-15 has-text-weight-bold">
                <span className="thick-underline ml-3 no-margin-mobile">
                  Profit
                </span>
              </div>

              <div className="column chart-legend mb-3">
                <div className="no-wrap is-inline-block">
                  <div className="legend-color has-bg-blue" />
                  <span className="is-family-secondary is-size-5">Profit</span>
                </div>

                <div className="no-wrap is-inline-block">
                  <div className="legend-color has-bg-orange ml-6 ml-3-mobile" />
                  <span className="is-family-secondary is-size-5">Users</span>
                </div>

                {/* <div className="cumulative-checkbox is-inline-block ml-6 has-text-weight-bold is-size-6">
                  <input
                    className="styled-checkbox"
                    id="styled-checkbox-1"
                    type="checkbox"
                    defaultChecked={chartDataType === CUMULATIVE}
                    value={CUMULATIVE}
                    onChange={handleChartDataChange}
                  />
                  <label htmlFor="styled-checkbox-1">
                    <span>Cumulative</span>
                  </label>
                </div> */}
              </div>

              <div className="axis-label-container column is-2 is-pulled-right is-size-15 has-text-weight-bold has-text-right">
                <span className="thick-underline">Users</span>
              </div>
            </div>

            <Chart
              dataLeft={chartData[0]}
              dataRight={chartData[1]}
              timeUnit={timeUnit}
              handleChartDataChange={handleChartDataChange}
              chartDataType={chartDataType}
              currencySymbol={currencySymbol}
            />
          </div>
        </div>

        <div className="right-column column is-one-quarter is-hidden-touch">
          <Scenario
            profit={profit[timeRange]}
            users={users[timeRange]}
            profitZeroString={profitZeroString}
            timeUnit={timeUnit}
            timeRange={timeRange}
            fundingNeeded={fundingNeeded}
            customerLifeTime={customerLifeTime}
            customerLifeTimeValue={customerLifeTimeValue}
            currencySymbol={currencySymbol}
          />

          <ShareButtonsContainer />
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  timeRange: PropTypes.number.isRequired,
  timeUnit: PropTypes.string.isRequired,
  chartDataType: PropTypes.string.isRequired,
  updateChartData: PropTypes.func.isRequired,
  profitZeroString: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  profit: PropTypes.arrayOf(PropTypes.number).isRequired,
  users: PropTypes.arrayOf(PropTypes.number).isRequired,
  fundingNeeded: PropTypes.number.isRequired,
  customerLifeTime: PropTypes.number.isRequired,
  customerLifeTimeValue: PropTypes.number.isRequired,
};

export default Results;
